// TokenProvider.js
import React, { useEffect, useContext, createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CheckSession, PingHome } from './apis';

export const TokenContext = createContext(null);
const portal = process.env.REACT_APP_BASEURL;

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenFromUrl = query.get('key');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      localStorage.setItem('authToken', tokenFromUrl);
      query.delete('key');
      const newUrl = `${location.pathname}?${query.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      setToken(localStorage.getItem('authToken'));
    }
  }, [location.search]);

  useEffect(() => {
    const intervalId = setInterval(checkSession, 60000); // Check session every 1 minute

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function checkSession() {
    CheckSession().then((data) => {
      if (data.data.remainingTime <= 2) {
        logout();
      } else {
        if (timoutPopUp) {
          withReactContent(Swal).close();
          timoutPopUp = undefined;
        }
      }
    });
  }

  function ping() {
    PingHome().then((data) => {});
  }

  var strHtml =
    '<h4>Do you want to continue your session?</h4><br/>' +
    'For security reason, your session will time out in <b></b> minutes unless you select the "Cancel" button below.';

  let timerInterval;
  let timoutPopUp;

  function logout() {
    if (timerInterval) return;

    timoutPopUp = withReactContent(Swal)
      .fire({
        title: 'Session timeout!',
        html: strHtml,
        showCancelButton: true,
        timer: 2 * 60 * 1000, //2 minutes
        timerProgressBar: true,
        didOpen: () => {
          withReactContent(Swal).showLoading();
          const b = withReactContent(Swal)
            .getHtmlContainer()
            .querySelector('b');
          timerInterval = setInterval(() => {
            const totalSeconds = Math.floor(
              withReactContent(Swal).getTimerLeft() / 1000
            );
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            b.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      })
      .then((result) => {
        if (result.dismiss === withReactContent(Swal).DismissReason.timer) {
          window.location.href = `${portal}Account/SignOut`;
        }

        if (result.dismiss === withReactContent(Swal).DismissReason.cancel) {
          timerInterval = 0;
          ping();
        }
      });
  }

  return token ? (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  ) : null;
};

export default TokenProvider;

export const useToken = () => {
  return useContext(TokenContext);
};
