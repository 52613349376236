import { create } from 'zustand';
import { GetNarrative } from '../apis';
import { ConvNarrativeData } from '../utils/convert';

const useReportNarrativeStore = create((set) => ({
  isLoading: false,
  data: {},
  reset: () => set({ data: {} }),
  update: (_new) => set({ data: _new }),
  setIsLoading: (_) => set({ isLoading: _ }),
  fetchNarrative: async (cid) => {
    set({ isLoading: true });
    const data = await loadNarrativeData(cid);
    set({ data: data, isLoading: false });
  }
}));

const loadNarrativeData = async (cid) => {
  if (!cid) return null;

  const { data, msg } = await GetNarrative(cid);
  if (!data) {
    return null;
  }

  const narrativeData = ConvNarrativeData(data.data);

  return narrativeData;
};

export default useReportNarrativeStore;
