import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import CompositeTrack from './CompositeTrack';

function calcBarWidth(min = 0, max = 100, value, reversed = false) {
  if (!value) {
    console.warn('No value found');
    return 0;
  }

  return ((value - min) / (max - min)) * 100;
}

const ItemRender = ({ item, scale, image = false }) => {
  return (
    <>
      {item.title ? <p className='bar-chart-bar-label'>{item.title}</p> : ''}
      <Tooltip
        className={'bg-white text-black shadow-md shadow-blue-gray-400 '}
        content={item.tooltip}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 }
        }}
      >
        <div className='bar-chart-bar-container '>
          {item.value ? (
            <div
              className='bar-chart-section-bar-item-bar'
              style={{
                width: `${calcBarWidth(
                  scale.min,
                  scale.max,
                  item.value,
                  scale.reversed
                )}%`
              }}
            ></div>
          ) : (
            ''
          )}
          {item.value ? (
            item.tooltip ? (
              <span
                style={{
                  height: image ? 20 : '',
                  marginBottom: image ? 10 : ''
                }}
                data-tippy-content={item.tooltip}
              >
                {item.value}
              </span>
            ) : (
              <span
                style={{
                  height: image ? 20 : '',
                  marginBottom: image ? 10 : ''
                }}
              >
                {item.value}
              </span>
            )
          ) : null}
        </div>
      </Tooltip>
    </>
  );
};

export default function RenderBarChartBars({ sections, scale, image = false }) {
  return (
    <>
      {sections.map((section, i) => {
        return (
          <div className='bar-chart-section-bar' key={'bcs-' + i}>
            {section.label ? (
              <div className='bar-chart-section-bar-label'>
                <span>{section.label}</span>
              </div>
            ) : (
              ''
            )}
            <div className='bar-chart-section-bar-items'>
              {section.bars.map((item, index) => {
                if (item.tooltip) {
                  return (
                    <div
                      className='bar-chart-section-bar-item'
                      data-tippy-trigger=''
                      key={'bcscb-' + index}
                    >
                      <ItemRender item={item} scale={scale} image={image} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className='bar-chart-section-bar-item'
                      key={'bcscb-' + index}
                    >
                      <ItemRender item={item} scale={scale} image={image} />
                    </div>
                  );
                }
              })}
              {!section.bars || section.bars.length == 0 ? (
                <div
                  className='bar-chart-section-bar-item'
                  data-tippy-trigger=''
                  key={'bcscb-0' + i}
                >
                  <ItemRender
                    item={{
                      value: section.composite.value,
                      title: ' ',
                      tooltip: section.composite.tooltip
                    }}
                    scale={scale}
                    image={image}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
