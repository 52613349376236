import React, { useEffect, useState } from 'react';
import useReportNarrativeStore from '../../store/report_narrative.store';
import { SkeletonBar } from '../SkeletonBar';
import { IconButton } from '@material-tailwind/react';
import clsx from 'clsx';
import { WhyDescDialog } from './edit/WhyDescDialog';
import { BiColorFill, BiSolidPencil } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { ImageDialog } from './edit/ImageDialog.jsx';
import { ColorPickerDialog } from './edit/ColorPickerDialog.jsx';
import { UpdateNarrativeImage } from '../../apis/Practice.js';
import useReportClientStore from '../../store/report_client.store.js';

const DiagnosesSection = ({}) => {
  const patientId = useParams('id').id;

  const narrativeData = useReportNarrativeStore((state) => state.data);
  const isLoading = useReportNarrativeStore((state) => state.isLoading);
  const fetchNarrative = useReportNarrativeStore(
    (state) => state.fetchNarrative
  );
  const client = useReportClientStore((state) => state.data);

  const [diagDesc, setDiagDesc] = useState(narrativeData?.['DIAGVIS']?.desc);
  const [diagListDesc, setDiagListDesc] = useState(
    narrativeData?.['DIAGVIS']?.list
  );

  const [isEditingDiagDesc, setIsEditingDiagDesc] = useState(false);
  const [isEditingDiagList, setIsEditingDiagList] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [image, setImage] = useState(
    narrativeData?.['DIAGVIS']?.imageUrl
      ? narrativeData?.['DIAGVIS']?.imageUrl
      : '/assets/images/stressed.jpg'
  );
  const color = '#3c2829';
  const onEditImage = () => {
    setIsEditingImage((isEditing) => !isEditing);
  };

  useEffect(() => {
    setDiagDesc(narrativeData?.['DIAGVIS']?.desc);
    setDiagListDesc(narrativeData?.['DIAGVIS']?.list);
    setImage(
      narrativeData?.['DIAGVIS']?.imageUrl
        ? narrativeData?.['DIAGVIS']?.imageUrl
        : '/assets/images/stressed.jpg'
    );
  }, [narrativeData]);

  const onEditDesc = () => {
    setIsEditingDiagDesc(!isEditingDiagDesc);
  };
  const onEditList = () => {
    setIsEditingDiagList(!isEditingDiagList);
  };
  const onUpdateCallback = async () => {
    await fetchNarrative(patientId);
  };
  const onUpdateCallbackForImage = async (img) => {
    await fetchNarrative(patientId);
    if (img) {
      setImage(img);
    }
  };
  const onEditColor = () => {
    setIsEditingColor((editing) => !editing);
  };
  return (
    <section className='mb-[30px]'>
      <div className='container'>
        <div
          className='tile flex flex-col gap-[30px] theme-light relative group/parent'
          style={{
            backgroundColor: narrativeData?.['RESOURCESVIS']?.color
              ? narrativeData?.['RESOURCESVIS']?.color
              : color
          }}
        >
          <div className='absolute top-2 right-2  hidden group-hover/parent:block'>
            <IconButton
              size='sm'
              color='black'
              data-ripple-light='true'
              data-dialog-target='dialog'
              className={clsx(
                ' bg-teal-500 w-3 h-3 px-3 ',
                'text-white text-md'
              )}
              onClick={() => onEditColor(0)}
            >
              <BiColorFill />
            </IconButton>
            <ColorPickerDialog
              open={isEditingColor}
              onToggle={onEditColor}
              defaultColor={
                narrativeData?.['RESOURCESVIS']?.color
                  ? narrativeData?.['RESOURCESVIS']?.color
                  : color
              }
              userDefaultColor={
                narrativeData?.['RESOURCESVIS']?.userDefaultColor
              }
              practiceDefaultColor={
                narrativeData?.['RESOURCESVIS']?.practiceDefaultColor
              }
              callBack={onUpdateCallback}
              nid={narrativeData?.['RESOURCESVIS']?.narrativeTypeId}
              isPracticeAdmin={client.isPracticeAdmin}
            />
          </div>
          <div className='flex flex-col md:flex-row items-center gap-[30px]'>
            <div className='flex flex-col basis-1/2 max-w-text grow gap-[30px]'>
              <p className='eyebrow'>Diagnoses</p>

              <div className='text-serif-h2 group relative '>
                <SkeletonBar rows={1} isLoading={isLoading} />
                {!isLoading && <p>{diagDesc}</p>}
                <div className='absolute top-[-5px] right-0  hidden group-hover:block'>
                  <IconButton
                    size='sm'
                    color='black'
                    data-ripple-light='true'
                    data-dialog-target='dialog'
                    className={clsx(
                      ' bg-teal-500 w-3 h-3 px-3 ',
                      'text-white text-md'
                    )}
                    onClick={onEditDesc}
                  >
                    <BiSolidPencil />
                  </IconButton>

                  <WhyDescDialog
                    open={isEditingDiagDesc}
                    dlgTitle={'"Diagnoses"'}
                    data={narrativeData?.['DIAGVIS']}
                    initValue={diagDesc}
                    onToggle={onEditDesc}
                    callBack={onUpdateCallback}
                  />
                </div>
              </div>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur. Vitae fringilla eleifend
                pharetra eros cursus. Nunc facilisis fames quam amet leo
                pellentesque at ante faucibus.{" "}
              </p> */}
            </div>

            <div className='basis-1/2 grow relative group'>
              <div className='absolute top-2 right-2  hidden group-hover:block'>
                <IconButton
                  size='sm'
                  color='black'
                  data-ripple-light='true'
                  data-dialog-target='dialog'
                  className={clsx(
                    ' bg-teal-500 w-3 h-3 px-3 ',
                    'text-white text-md'
                  )}
                  onClick={onEditImage}
                >
                  <BiSolidPencil />
                </IconButton>

                <ImageDialog
                  open={isEditingImage}
                  defaultImage={image}
                  userDefaultImage={
                    narrativeData?.['GOINGWELLHEAD']?.userDefaultImageUrl
                  }
                  practiceDefaultImage={
                    narrativeData?.['GOINGWELLHEAD']?.practiceDefaultImageUrl
                  }
                  onToggle={onEditImage}
                  callBack={onUpdateCallbackForImage}
                  cropSize={{ width: 400, height: 200 }}
                  updateImage={(image, j, k) =>
                    UpdateNarrativeImage(
                      narrativeData?.['DIAGVIS']?.narrativeTypeId,
                      image,
                      j,
                      k
                    )
                  }
                  isPracticeAdmin={client.isPracticeAdmin}
                />
              </div>
              <img
                className='rounded-[10px]'
                src={image}
                alt='Placeholder Image'
              />
            </div>
          </div>

          <div className='flex flex-wrap gap-[30px] justify-evenly group relative '>
            <div className='absolute top-0 right-0  hidden group-hover:block'>
              <IconButton
                size='sm'
                color='black'
                data-ripple-light='true'
                data-dialog-target='dialog'
                className={clsx(
                  ' bg-teal-500 w-3 h-3 px-3 ',
                  'text-white text-md'
                )}
                onClick={onEditList}
              >
                <BiSolidPencil />
              </IconButton>

              <WhyDescDialog
                dlgTitle={'"Diagnoses"'}
                open={isEditingDiagList}
                data={narrativeData?.['DIAGVIS']}
                initValue={diagListDesc}
                isList={true}
                isSimpleList={false}
                listItemFields={['title', 'description']}
                listItemInputTypes={['input', 'textarea']}
                onToggle={onEditList}
                callBack={onUpdateCallback}
              />
            </div>

            {!isLoading &&
              diagListDesc?.map((item, index) => {
                return (
                  <div key={'dndv-' + index} className='tile-card'>
                    <div className='mb-5 inline-block  p-5 rounded-[5px] bg-blue-shade '>
                      <span className='h-[30px] w-[30px]'></span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        fill='none'
                      >
                        <g
                          stroke='#000'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='3'
                          clipPath='url(#a)'
                        >
                          <path d='M14.966 1.674v2.142M14.966 9.866v2.143M14.966 18.058V20.2M14.966 26.25v2.143M2.33 22.436C5.592 19.762 7.22 18.133 9.764 15c-2.544-3.133-4.174-4.761-7.436-7.436M27.566 22.436c-3.262-2.674-4.892-4.303-7.436-7.436 2.544-3.133 4.174-4.761 7.436-7.436'></path>
                        </g>
                        <defs>
                          <clippath id='a'>
                            <path fill='#fff' d='M0 0h30v30H0z'></path>
                          </clippath>
                        </defs>
                      </svg>
                    </div>

                    <div className='mb-2'>
                      <p className='font-bold'>{item.title}</p>

                      {/* <ul>
                        <li className="text-neutral-3 font-bold text-sm">
                          314.01 (F90.2)
                        </li>

                        <li className="text-neutral-3 font-bold text-sm">
                          Combined Presentation
                        </li>
                      </ul> */}
                    </div>

                    <div>{item.description}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiagnosesSection;
