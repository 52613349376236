import useReportTableStore from '../../store/report_table.store';
import DotPlot from '../DotPlot';
import RenderBarChart from '../RenderBarChart';

const AcademicChartSection = ({ image = false }) => {
  const reportTables = useReportTableStore((state) => state.tables);

  if (!reportTables || !reportTables.length) return null;

  return (
    <>
      {reportTables.map((table, i) => {
        const tableType = table.type;

        if (tableType?.toLowerCase() == 'redot') {
          return (
            <div className={i % 2 == 0 && i != 0 ? 'page-break' : ''}>
              <section className='mb-[30px]' key={'dtd-' + i}>
                <div id={table.reportType} className='container'>
                  <div className='p-4 border border-gray rounded-[20px]'>
                    <div className='mb-[30px]'>
                      <p className='eyebrow'>{table.title}</p>
                      {table.description ? (
                        <div className='graph-scale-title col-span-3 mb-[10px]'>
                          <p>{table.description}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='dot-plot-sections' id='graph-example'>
                      <DotPlot data={table} image={image} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        } else if (tableType?.toLowerCase() == 'rebar') {
          return (
            <div className={i % 2 == 0 && i != 0 ? 'page-break' : ''}>
              <section className='mb-[30px]' key={'dtdac-' + i}>
                <div id={table.reportType} className='container'>
                  <div className='p-4 border border-gray rounded-[20px]'>
                    <div className='mb-[30px]'>
                      <p className='eyebrow'>{table.title}</p>
                      {table.description ? (
                        <div className='graph-scale-title col-span-3 mb-[10px]'>
                          <p>{table.description}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className='bar-chart-sections' id='bar-example'>
                      {[table].map((graph, i) => {
                        return (
                          <RenderBarChart
                            key={'bgd-' + i}
                            data={graph}
                            image={image}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        }
      })}
    </>
  );
};

export default AcademicChartSection;
