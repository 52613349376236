import '../App.css';

import Header from '../components/Header';

import { useEffect, useRef } from 'react';
import { GetNarrative, GetScoreReports } from '../apis';
import { GetPracticeInfo } from '../apis/Practice.js';
import DescriptionSections from '../components/home/DescriptionSections';
import DiagnosesSection from '../components/home/DiagnosesSection';
import RecommendSection from '../components/home/RecommendSection';
import PieChartSection from '../components/home/PieChartSection';
import ResourceSections from '../components/home/ResourceSections';
import AcademicChartSection from '../components/home/AcademicChartSection';
import { ConvDotPlotData, ConvNarrativeData } from '../utils/convert';
import useReportTableStore from '../store/report_table.store';
import { Spinner } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';

import { toastError } from '../utils/toast';

import 'react-toastify/dist/ReactToastify.css';
import useReportNarrativeStore from '../store/report_narrative.store';
import useReportClientStore from '../store/report_client.store';
import html2pdf from 'html2pdf.js';

function Home() {
  const loadingRef = useRef(false);
  const loadingRefNar = useRef(false);

  const patientId = useParams('id').id;
  const updateReportTables = useReportTableStore((state) => state.update);
  const setIsScoreLoading = useReportTableStore((state) => state.setIsLoading);

  const updateNarrative = useReportNarrativeStore((state) => state.update);
  const setIsNarrativeLoading = useReportNarrativeStore(
    (state) => state.setIsLoading
  );
  const isLoading = useReportNarrativeStore((state) => state.isLoading);

  const updateClient = useReportClientStore((state) => state.update);
  const setIsClientLoading = useReportClientStore(
    (state) => state.setIsLoading
  );

  const printRef = useRef();
  const downloadBtnRef = useRef();

  const handleDownloadPdf = () => {
    const element = printRef.current;
    downloadBtnRef.current.textContent = 'Downloading...';
    const elementWidth = Math.min(element.scrollWidth, 1600);
    const options = {
      margin: 0,
      filename: 'report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        logging: true,
        useCORS: true,
        letterRendering: true
      },
      jsPDF: {
        unit: 'pt',
        format: [elementWidth, elementWidth * 1.45],
        orientation: 'portrait'
      }
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .catch((error) => {
        console.error('Error generating PDF:', error);
      })
      .finally(() => {
        if (downloadBtnRef) {
          downloadBtnRef.current.textContent = 'Download Report';
        }
      });
  };

  const loadScoreReport = async (cid) => {
    if (!cid) return null;
    if (loadingRef.current) return;
    loadingRef.current = true;

    setIsScoreLoading(true);

    const { data, msg } = await GetScoreReports(cid);

    if (!data) {
      loadingRef.current = false;
      setIsScoreLoading(false);
      toastError(msg);
      return;
    }

    const tables = ConvDotPlotData(data.data);

    updateReportTables(tables);
    loadingRef.current = false;
    setIsScoreLoading(false);
  };

  const loadNarrativeData = async (cid) => {
    if (!cid) return null;
    loadingRefNar.current = true;

    setIsNarrativeLoading(true);

    const { data, msg } = await GetNarrative(cid);

    if (!data) {
      loadingRefNar.current = false;
      setIsNarrativeLoading(false);
      toastError(msg);
      return;
    }

    const narrativeData = ConvNarrativeData(data.data);

    updateNarrative(narrativeData);
    setIsNarrativeLoading(false);
    loadingRefNar.current = false;
  };

  const loadClientInfo = async (cid) => {
    setIsClientLoading(true);
    const { data: response, msg } = await GetPracticeInfo(patientId);

    if (!response || !response.data) {
      setIsClientLoading(false);

      toastError(msg ? msg : 'Failed to get client data.');
      return;
    }

    updateClient(response.data);
    setIsClientLoading(false);
  };

  useEffect(() => {
    loadScoreReport(patientId);
    loadNarrativeData(patientId);
    loadClientInfo(patientId);
  }, [patientId]);

  return (
    <>
      <button
        class='fixed button button-primary top-10 right-20 z-50'
        onClick={handleDownloadPdf}
        ref={downloadBtnRef}
      >
        Download Report
      </button>
      <div className='home' ref={printRef}>
        <Header cid={patientId} />

        <DescriptionSections />

        <div className='page-break'></div>
        <DiagnosesSection />

        <RecommendSection />
        {loadingRefNar.current && isLoading ? (
          <Spinner className='h-10 w-10 mx-auto my-10 text-red' color='white' />
        ) : (
          <ResourceSections />
        )}

        {loadingRefNar.current && isLoading ? (
          <Spinner className='h-10 w-10 mx-auto my-10 text-red' color='white' />
        ) : (
          <PieChartSection />
        )}

        {loadingRef.current && isLoading ? (
          <Spinner className='h-10 w-10 mx-auto my-10 text-red' color='white' />
        ) : (
          <AcademicChartSection />
        )}

        {/* <SocialChartSection /> */}
      </div>
    </>
  );
}

export default Home;
