import clsx from 'clsx';

export const SkeletonBar = ({ rows = 3, isLoading = false, className }) => {
  if (!isLoading) return null;
  return (
    <>
      {new Array(rows).fill(1).map((row, i) => {
        return (
          <div
            key={'sak-' + i}
            className={clsx(
              'animate-pulse w-full block h-5 mb-2 font-sans text-base antialiased font-light leading-relaxed bg-gray rounded-md text-inherit ',
              className
            )}
          >
            &nbsp;
          </div>
        );
      })}
    </>
  );
};
