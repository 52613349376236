import { useState } from 'react';
import { Spinner } from '@material-tailwind/react';

function SpinnerPage({ message }) {
  return (
    <div className='spinner-wrapper'>
      <Spinner
        className='h-16 w-16 spinner-color-yellow'
        style={{ margin: '0 auto', marginTop: 'calc(100vh - 100vh / 2)' }}
      ></Spinner>
      <p
        style={{
          width: '300px',
          fontWeight: 700,
          textAlign: 'center',
          margin: '0 auto',
          marginTop: '20px'
        }}
      >
        {message}
      </p>
    </div>
  );
}

export default SpinnerPage;
