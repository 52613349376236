import { useEffect, useState } from 'react';
import RenderBarChartBars from './RenderBarchartBars';
import RenderGraphGradient from './RenderGraphGradient';
import RenderGraphScaleItems from './RenderGraphScaleItems';
import RenderGraphScaleLabels from './RenderGraphScaleLabels';

export default function RenderBarChart({ data, image = false }) {
  // append a new div to the chart with class .bar-chart-section

  // get the scale data
  const {
    min,
    max,
    step,
    gradient,
    noNumber,
    dotSections,
    reversed,
    scaleLabel,
    labels
  } = data.scale;

  const [isItemsRendered, setIsItemsRendered] = useState(false);
  useEffect(() => {
    const checkDivExists = () => {
      const element = document.querySelector('.bar-chart-section');
      if (element) {
        setIsItemsRendered(true);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkDivExists, 100);

    setTimeout(() => {
      setIsItemsRendered(true);
      clearInterval(intervalId);
    }, 3000);
  }, []);

  return (
    <div className='bar-chart-section'>
      <div className='bar-chart-section-bars'>
        <div
          className='grid grid-cols-12 absolute top-0 left-0 w-full h-full'
          style={{ zIndex: '-1' }}
        >
          {data.scale?.scaleLabel ? (
            <div className='graph-scale-title col-span-3'>
              <p>{data.scale.scaleLabel}</p>
            </div>
          ) : (
            ''
          )}
          <div className='graph-scale col-span-9 col-start-4 flex-col'>
            <div className='graph-scale-labels w-full flex flex-row grow-0 shrink justify-between relative'>
              {isItemsRendered && (
                <RenderGraphScaleLabels
                  min={min}
                  max={max}
                  step={step}
                  noNumber={noNumber}
                  dotSections={dotSections}
                  labels={labels}
                  reversed={reversed}
                />
              )}
            </div>
            <div className='graph-scale-items relative flex flex-row shrink-0 grow justify-between'>
              <RenderGraphGradient min={min} max={max} gradient={gradient} />

              <RenderGraphScaleItems
                min={min}
                max={max}
                step={step}
                noNumber={noNumber}
                dotSections={dotSections}
                labels={labels}
                reversed={reversed}
              />
            </div>
          </div>
        </div>
        <RenderBarChartBars
          sections={data.sections}
          scale={data.scale}
          image={image}
        />
      </div>
    </div>
  );
}
