import React from 'react';

export default function RenderGraphGradient({ min, max, gradient }) {
  let minStop = ((gradient?.minStop - min) / (max - min)) * 100;
  let midStop = ((gradient?.midStop - min) / (max - min)) * 100;
  let maxStop = ((gradient?.maxStop - min) / (max - min)) * 100;

  const lineargradient = `linear-gradient(90deg, ${gradient?.minColor} ${minStop - 2}%, ${gradient?.midColor} ${minStop + 2}%, ${gradient?.midColor} ${midStop - 2}%, ${gradient?.maxColor} ${midStop + 2}%, ${gradient?.maxColor} ${maxStop}%)`;

  return (
    <div
      className='graph-scale-bg'
      style={{ backgroundImage: lineargradient }}
    ></div>
  );
}
