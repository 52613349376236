import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Spinner
} from '@material-tailwind/react';

export const DefaultDialog = ({
  open,
  onToggle,
  practiceDefault,
  userDefault,
  setPracticeDefault,
  setUserDefault,
  colorOrImage,
  isPracticeAdmin,
  isSubmitting
}) => {
  const renderPreview = (item) => {
    if (!item) return <div>No default set</div>;

    return colorOrImage === 'color' ? (
      <div
        style={{
          backgroundColor: item,
          width: '100%',
          height: '150px',
          borderRadius: '8px'
        }}
      ></div>
    ) : (
      <img
        src={item}
        alt='Default Preview'
        style={{
          maxWidth: '100%',
          height: '150px',
          objectFit: 'cover',
          borderRadius: '8px'
        }}
      />
    );
  };

  return (
    <Dialog open={open} handler={onToggle}>
      <DialogHeader>Manage Defaults</DialogHeader>
      <DialogBody className='!max-h-[80vh] overflow-auto space-y-4'>
        <div>
          <h3 className='font-medium mb-2'>User Default</h3>
          {renderPreview(userDefault)}
        </div>
        <div>
          <h3 className='font-medium mb-2'>Practice Default</h3>
          {renderPreview(practiceDefault)}
        </div>
      </DialogBody>
      <DialogFooter className='justify-between'>
        <Button
          variant='text'
          color='red'
          onClick={onToggle}
          className='mr-1 text-red-500'
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant='gradient'
          color='green'
          className='bg-teal-500'
          onClick={() => {
            setUserDefault();
            onToggle();
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner className='h-5 w-5 mx-auto text-blue-500' color='white' />
          ) : (
            <span>Save User Default</span>
          )}
        </Button>
        {isPracticeAdmin && (
          <Button
            variant='gradient'
            color='green'
            className='bg-teal-500'
            onClick={() => {
              setPracticeDefault();
              onToggle();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner
                className='h-5 w-5 mx-auto text-blue-500'
                color='white'
              />
            ) : (
              <span>Save Practice Default</span>
            )}
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
};
