export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function formatPolarData(data) {
  if (!data || !data.length) {
    console.warn('No data found');
    return;
  }

  return data.map((item) => {
    let size = 0;

    if (!item.value) {
      console.warn('No value found');
      return;
    }

    // if the value is 0 - 1, subtract it from 1.1, if it's 1 - 100, subtract it from 110
    if (item.value <= 1) {
      size = 1.1 - item.value;
    } else {
      size = 1.1 - item.value / 100;
    }

    const halo = {
      size: size,
      opacity: 0.25
    };

    return {
      name: item.name || '',
      y: 10,
      z: item.value,

      description: item.description || '',
      halo: halo
    };
  });
}

export function applyBorderRadius(path, i, r) {
  const a = path[i];

  let b = path[i + 1];
  if (b[0] === 'Z') {
    b = path[0];
  }

  let line, arc, fromLineToArc;

  // From straight line to arc
  if ((a[0] === 'M' || a[0] === 'L') && b[0] === 'A') {
    line = a;
    arc = b;
    fromLineToArc = true;

    // From arc to straight line
  } else if (a[0] === 'A' && (b[0] === 'M' || b[0] === 'L')) {
    line = b;
    arc = a;
  }

  if (line && arc && arc.params) {
    const bigR = arc[1],
      // In our use cases, outer pie slice arcs are clockwise and inner
      // arcs (donut/sunburst etc) are anti-clockwise
      clockwise = arc[5],
      params = arc.params,
      { start, end, cx, cy } = params;

    // Some geometric constants
    const relativeR = clockwise ? bigR - r : bigR + r,
      // The angle, on the big arc, that the border radius arc takes up
      angleOfBorderRadius = relativeR ? Math.asin(r / relativeR) : 0,
      angleOffset = clockwise ? angleOfBorderRadius : -angleOfBorderRadius,
      // The distance along the radius of the big arc to the starting
      // point of the small border radius arc
      distanceBigCenterToStartArc = Math.cos(angleOfBorderRadius) * relativeR;

    // From line to arc
    if (fromLineToArc) {
      // Update the cache
      params.start = start + angleOffset;

      // First move to the start position at the radial line. We want to
      // start one borderRadius closer to the center.
      line[1] = cx + distanceBigCenterToStartArc * Math.cos(start);
      line[2] = cy + distanceBigCenterToStartArc * Math.sin(start);

      // Now draw an arc towards the point where the small circle touches
      // the great circle.
      path.splice(i + 1, 0, [
        'A',
        r,
        r,
        0, // slanting,
        0, // long arc
        1, // clockwise
        cx + bigR * Math.cos(params.start),
        cy + bigR * Math.sin(params.start)
      ]);

      // From arc to line
    } else {
      // Update the cache
      params.end = end - angleOffset;

      // End the big arc a bit earlier
      arc[6] = cx + bigR * Math.cos(params.end);
      arc[7] = cy + bigR * Math.sin(params.end);

      // Draw a small arc towards a point on the end angle, but one
      // borderRadius closer to the center relative to the perimeter.
      path.splice(i + 1, 0, [
        'A',
        r,
        r,
        0,
        0,
        1,
        cx + distanceBigCenterToStartArc * Math.cos(end),
        cy + distanceBigCenterToStartArc * Math.sin(end)
      ]);
    }

    // Long or short arc must be reconsidered because we have modified the
    // start and end points
    arc[4] = Math.abs(params.end - params.start) < Math.PI ? 0 : 1;
  }
}

export function polarChartOnLoad() {
  var series = this.series[0];
  series.points.forEach((point) => {
    const haloSize = point.shapeArgs.y * 1.05 - point.shapeArgs.r;
    const color = point.color;
    const innerR = point.shapeArgs.r + 1;
    const borderRadius = 10;
    const start = point.shapeArgs.start;
    const end = point.shapeArgs.end;
    const alpha = end - start;

    const halo = this.renderer.symbols.arc(
      point.shapeArgs.x,
      point.shapeArgs.y,
      point.shapeArgs.r + haloSize,
      point.shapeArgs.r + haloSize,
      {
        innerR: innerR,
        start: start * 1.001,
        end: end * 0.999
      }
    );

    const innerBorderRadius = Math.min(
      borderRadius,
      2 * (alpha / Math.PI) * innerR
    );

    let i = halo.length - 1;
    while (i--) {
      if (i <= 1) {
        applyBorderRadius(halo, i, innerBorderRadius);
      }
    }

    this.renderer
      .path(halo)
      .attr({
        fill: color,
        zIndex: 0,
        opacity: 0.2,
        'stroke-width': 0,
        stroke: 'none'
      })
      .add(series.group);

    // make it so that whena  user hovers, the tooltip is rendered
    point.graphic.on('mouseover', function () {
      point.setState('hover');
      point.onMouseOver();
    });

    point.graphic.on('mouseout', function () {
      point.setState('');
      point.onMouseOut();
    });
  });
}

export function buildDotPlotText(value, confidence) {
  let conf = null;
  let confMin = null;
  let confMax = null;
  let val = null;
  let valMin = null;
  let valMax = null;

  if (Array.isArray(value)) {
    [valMin, valMax] = value;
  } else {
    val = value;
  }

  if (Array.isArray(confidence)) {
    [confMin, confMax] = confidence;
  } else {
    conf = confidence;
  }

  let text = '';

  if (val) {
    text = val;
  }

  if (valMin && valMax) {
    text = `${valMin} - ${valMax}`;
  }

  if (conf) {
    text += ` conf: (${conf})`;
  }

  if (confMin && confMax) {
    text += ` conf: (${confMin} - ${confMax})`;
  }

  return text;
}

export function CalcDataPoint(
  // el = null,
  globalMin = 0,
  globalMax = 100,
  min = null,
  max = null,
  value = null
) {
  let barWidth = '6px';
  let barLeft = '0';

  if (min && max) {
    barWidth = ((max - min) / (globalMax - globalMin)) * 100;
    barLeft = ((min - globalMin) / (globalMax - globalMin)) * 100;
  }

  // If we only have min or only have max, and no value, we set the value to the one we have
  if (min && !max && !value) {
    value = min;
  }

  if (max && !min && !value) {
    value = max;
  }

  if (value) {
    barLeft = ((value - globalMin) / (globalMax - globalMin)) * 100;

    const left = `${barLeft}%`;
    const width = `${barWidth}`;
    const leftDis = `calc(${barLeft}% + ${barWidth} + 2px)`;
    return { left, width, leftDis };
  } else {
    const left = `${barLeft}%`;
    const width = `${barWidth}%`;
    const leftDis = `calc(${barLeft}% + ${barWidth}% + 2px)`;
    return { left, width, leftDis };
  }
}
