import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASEURL;

export async function GetPracticeInfo(cid) {
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const data = await axios.get(baseUrl + `api/Practice/Info/${cid}`);
    return { data };
  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

const getURLFromOption = (option, patientId) =>
  `${option == 2 ? '' : option == 1 ? 'UD' : 'PD'}${option ? '' : '/' + patientId}`;
const getNVPName = (option) =>
  option == 2
    ? 'PracticeNarrativeColor'
    : option == 1
      ? 'UserNarrativeColor'
      : 'PatientNarrativeColor';

export async function UpdateNarrativeColor(
  nid,
  color,
  saveAs = 0,
  patientId = ''
) {
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const url = baseUrl + 'api/Practice/NarrativeColor';
    const body = {
      Name: getNVPName(saveAs),
      Value: color,
      PK: nid
    };
    let data = await axios.post(url + getURLFromOption(0, patientId), body);
    if (saveAs) {
      data = await axios.post(url + getURLFromOption(saveAs, patientId), body);
    }
    return { data };
  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function UpdateNarrativeImage(
  nid,
  image,
  saveAs = 0,
  patientId = ''
) {
  try {
    let key = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = key;
    const formData = new FormData();
    formData.append('file', image);

    const url = baseUrl + 'api/Practice/NarrativeImage';

    let data = await axios.post(
      `${url}${getURLFromOption(0, patientId)}/${nid}`,
      formData
    );
    if (saveAs) {
      data = await axios.post(
        `${url}${getURLFromOption(saveAs, patientId)}/${nid}`,
        formData
      );
    }
    return { data };
  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function UpdateVisualizationHeader(
  image,
  saveAs = 0,
  patientId = ''
) {
  try {
    let key = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('file', image);

    const url = baseUrl + 'api/Practice/VisualizationHeader';

    let data = await axios.post(
      url + getURLFromOption(0, patientId),
      formData,
      {
        headers: { Authorization: key }
      }
    );
    if (saveAs) {
      data = await axios.post(
        url + getURLFromOption(saveAs, patientId),
        formData,
        {
          headers: { Authorization: key }
        }
      );
    }
    return { data };
  } catch (ex) {
    return { data: null, msg: ex.message };
  }
}

export async function GetDocument(id) {
  try {
    let key = localStorage.getItem('authToken');
    const response = await axios.get(
      baseUrl + `api/Practice/RecommendationBanks/${id}`,
      {
        headers: { Authorization: key }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching document:', error);
  }
}

export async function SaveDocument(blob, fileName, dictionaryId, id, bankName) {
  try {
    let key = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('files', blob, fileName);
    formData.append('SubmissionType', 'PracticeFile');
    const response = await axios.post(
      baseUrl +
        `api/Practice/UploadedBanks/${id}?dictionaryId=${dictionaryId}&bankName=${bankName}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: key
        }
      }
    );
    return response.status;
  } catch (ex) {
    throw ex.message;
  }
}
