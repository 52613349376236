function PageNotFound() {
  return (
    <div>
      <div class='container'>
        <div class='copy-container center-xy'>
          <p>404, page not found.</p>
          <span class='handle'></span>
        </div>
      </div>
    </div>
  );
}
export default PageNotFound;
