import clsx from 'clsx';

export default function RenderGraphScaleLabels(
  // el,
  {
    min = 0,
    max = 100,
    step = 10,
    noNumber = [],
    dotSections = [],
    atRisk = [],
    reversed = false,
    scaleLabel = null,
    labels
  }
) {
  if (dotSections) {
    var prevLeft = 0;
    return (
      <>
        {dotSections.map((key, i) => {
          let leftClass = '';

          const element = document.querySelector(`.${key.id}`);
          let constLeft = 30;
          if (i == 0) constLeft = 100;

          if (element) {
            const parent = element.parentNode;
            const elementRect = element.getBoundingClientRect();
            const parentRect = parent.getBoundingClientRect();
            let left = elementRect.left - parentRect.left;
            if (i - 1 >= 0) {
              const sib = document.querySelector(`.${dotSections[i - 1].id}`);
              if (!!sib) {
                const sibRect = sib.getBoundingClientRect();
                left = (left + (sibRect.left - parentRect.left)) / 2;
              }
              constLeft = 15;
            }

            if (prevLeft + 60 >= left - constLeft)
              left += prevLeft + 60 - (left - constLeft);
            prevLeft = left - constLeft;
            leftClass = 'calc(' + left + 'px - ' + constLeft + 'px)';
          }

          return (
            <div
              key={'dk-label-' + key.range.max}
              className={clsx('graph-scale-label')}
              style={{ left: leftClass }}
            >
              {key.name}
            </div>
          );
        })}
      </>
    );
  }

  return null;
}
