import clsx from 'clsx';
import RenderGraphBars from './RenderGraphBars';
import InjectDotPlotScale from './InjectDotPlotScale';

export default function DotPlot({ data, image = false }) {
  if (!data || !data.sections || !data.sections.length || !data.scale) {
    console.warn('No data found');
    return null;
  }

  const { min, max, step, noNumber, noDots, labels } = data.scale;

  return (
    <>
      <InjectDotPlotScale data={data} />

      <RenderGraphBars
        sections={data.sections}
        min={min}
        max={max}
        image={image}
      />
    </>
  );
}
