import { create } from 'zustand';

const useReportTableStore = create((set) => ({
  isLoading: false,
  tables: [],
  reset: () => set({ tables: [] }),
  update: (_new) => set({ tables: _new }),
  setIsLoading: (_) => set({ isLoading: _ })
}));

export default useReportTableStore;
