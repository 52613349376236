import DotPlotSection from './DotPlotSection';

export default function RenderGraphBars({
  sections = [],
  min = 0,
  max = 100,
  image = false
}) {
  // if no sections of empty
  if (!sections || !sections.length) {
    console.warn('No section data');
    return null;
  }

  return (
    <>
      {sections.map((section, i) => {
        return (
          <DotPlotSection
            key={'dps-' + i}
            section={section}
            min={min}
            max={max}
            image={image}
          />
        );
      })}
    </>
  );
}
