import Highcharts from 'highcharts/highstock';
import VariablePie from 'highcharts/modules/variable-pie.js';
import HighchartsReact from 'highcharts-react-official';
import useReportNarrativeStore from '../../store/report_narrative.store';
import useReportClientStore from '../../store/report_client.store';
import { formatPolarData, polarChartOnLoad } from '../../utils/index';
VariablePie(Highcharts);

const PieChartSection = ({}) => {
  const narrativeData = useReportNarrativeStore((state) => state.data);
  const chList = narrativeData['TOTALSCOREVIS']?.list;
  const chartData = PieChartData(chList);
  const client = useReportClientStore((state) => state.data);
  const isLoading = useReportClientStore((state) => state.isLoading);

  return (
    <section className='mb-[30px] md:mb-12 lg:mb-24'>
      <div className='container-wide'>
        <div className='flex flex-col px-[15px]'>
          <h2 className='text-serif-h2 mb-16'>
            {client?.firstName}’s test results
          </h2>

          <HighchartsReact highcharts={Highcharts} options={chartData} />

          <div className='max-w-text px-[15px] mx-auto'>
            <p className='mt-10 text-sm'></p>
          </div>
        </div>
      </div>
    </section>
  );
};

const PieChartData = (data) => {
  return {
    chart: {
      reflow: true,
      height: 465,
      type: 'variablepie',
      events: {
        load: polarChartOnLoad
      }
    },
    title: {
      text: undefined
    },
    exporting: {
      enabled: false
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<div style="max-width:200px;"><strong>{point.name}</strong><br/>' +
        'Score: <b>{point.z}</b><br/>' +
        '<p>{point.description}</p></div>'
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      itemStyle: {
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        animation: false
      },
      variablepie: {
        showInLegend: true,
        cursor: 'pointer',
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
          connectorWidth: 0
        },
        states: {
          hover: {
            halo: null
          }
        },
        point: {
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
              return false;
            }
          }
        }
      }
    },
    series: [
      {
        minPointSize: 0,
        innerSize: '0%',
        zMin: 0,
        name: 'clientScoreChart',
        data: formatPolarData(data),
        colors: [
          '#F5FF94',
          '#B8E892',
          '#83D099',
          '#55B49D',
          '#44989B',
          '#357A8F',
          '#275D7A',
          '#27405D',
          '#132F50',
          '#021A36'
        ]
      }
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1023
          },
          chartOptions: {
            chart: {
              width: 700
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        },
        {
          condition: {
            maxWidth: 767
          },
          chartOptions: {
            chart: {
              width: 400
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        },
        {
          condition: {
            maxWidth: 639
          },
          chartOptions: {
            chart: {
              width: 280
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }
      ]
    }
  };
};

export default PieChartSection;
