import './App.css';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import Home from './pages/Home';
import { Template } from './pages/Template';
import EditNarrative from './components/narratives/EditNarrative';
import Finalize from './components/narratives/Finalize';
import PageNotFound from './pages/404';
import TokenProvider from './TokenProvider';
import UnUsedNarratives from './components/narratives/UnUsedNarrative';
import EditDocument from './components/editDocument/EditDocument';

function App() {
  return (
    <TokenProvider>
      <Routes>
        <Route path='/report/:id' element={<Home />}></Route>
        <Route
          path='createTemplate/:id/:tempId?'
          element={<Template />}
        ></Route>
        <Route
          path='editNarrative/:id/:evalId?'
          element={<EditNarrative />}
        ></Route>
        <Route
          path='unUsedNarrative/:id/:evalId?'
          element={<UnUsedNarratives />}
        ></Route>
        <Route path='finalize' element={<Finalize />}></Route>
        <Route path='editDocument/:id' element={<EditDocument />}></Route>
        <Route path='404' element={<PageNotFound />}></Route>

        <Route path='*' element={<Navigate to={'/404'} />} />
      </Routes>
    </TokenProvider>
  );
}

export default App;
